.AnnouncementBar {
  border-bottom: 2mm solid rgba(254, 221, 0, 1);
}

.AnnouncementBar_inner {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  line-height: 40px;
  padding: 8px 30px;
  border-bottom: 2mm solid rgba(0, 87, 184, 1);
}
