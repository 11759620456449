.Menu {
  background-color: #282c34;
}
ul.Menu_header li {
  display: inline;
  list-style-type: none;
  margin: 0;
}
ul.Menu_header {
  text-align: center;
  background-color: #111;
  padding: 0;
}
ul.Menu_header li a {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  padding: 20px;
  display: inline-block;
}
.Content {
  color: #fff;
  background-color: #282c34;
  padding: 20px;
  flex: 1;
}
.Content h2 {
  padding: 0;
  margin: 0;
}
.Content li {
  margin-bottom: 10px;
}
.Footer {
  clear: both;
  position: relative;
  padding: 20px;
  background-color: #5e626b;
  color: #fff;
}
