.Button {
  display: flex;
  align-items: center;
  font-family: inherit;
  font-weight: 500;
  font-size: 16px;
  padding: 0.7em 1.4em 0.7em 1.1em;
  color: white;
  background: #ad5389;
  background: linear-gradient(0deg, rgb(41, 95, 95) 0%, rgb(82, 167, 189) 100%);
  border: none;
  box-shadow: 0 0.7em 1.5em -0.5em #0e485f98;
  letter-spacing: 0.05em;
  border-radius: 10em;
  cursor: pointer;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.Button:hover {
  box-shadow: 0 0.5em 1.5em -0.5em #144fa798;
}

.Button:active {
  box-shadow: 0 0.3em 1em -0.5em #1423a798;
}
